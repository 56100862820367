import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import * as authAction from '../../actions/authAction';
import { Helmet } from 'react-helmet';
import Loader from '../../components/Loader';
import ReCAPTCHABlock from "../../components/ReCAPTCHABlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            showPassword: false
        };
        this.recaptchaRef = React.createRef();
        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.verifySubmit = this.verifySubmit.bind(this);
        this.verifiedSubmit = this.verifiedSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    verifySubmit(evt) {
        evt.preventDefault();
        this.recaptchaRef.execute();
    }

    verifiedSubmit() {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.login(this.state.email, this.state.password, this.props.history);
    }
    togglePasswordVisibility = () =>{
        this.setState((prevState) =>({
            showPassword:!prevState.showPassword
        }));
    }

    render() {
        return (
            <div className="container login-register-wrapper">
                <Helmet>
                    <title>Login</title>
                </Helmet>
                <ReCAPTCHABlock ref={el => this.recaptchaRef = el} onSubmit={this.verifiedSubmit} />
                <div className="row justify-content-md-center">
                    <div className="col-lg-8 col-md-16 col-sm-24">
                        <img src="/images/logo1.png" className="logo" alt="" />
                        <h4>Sign In</h4>
                        <p>With your Daisy-Health Account</p>
                        <Loader />
                        <form onSubmit={this.verifySubmit}>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Email" id="email" name="email" value={this.state.email} onChange={this.handleChange} autoComplete="off" />
                                {this.validator.message('email', this.state.email, 'required')}
                            </div>
                            <div className="form-group" style={{position:"relative"}}>
                                <input type={this.state.showPassword?"text":"password"} className="form-control" placeholder="Password" id="password" name="password" value={this.state.password} onChange={this.handleChange} autoComplete="off" />
                                <span onClick={this.togglePasswordVisibility} style={{position: "absolute",right: "30px",top: "50%",transform: "translateY(-50%)",cursor: "pointer",color: "#777"}}>
                                     <FontAwesomeIcon icon={this.state.showPassword ? faEye :faEyeSlash } />
                                    </span>
                                {this.validator.message('password', this.state.password, 'required')}
                            </div>
                            <div className="form-group clearfix">
                                <button type="submit" className="btn btn-primary float-left">Sign In</button>
                                <Link to="/forgot-password" className="float-right forgot-password">Forgot Password?</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.session.authenticated,
});

const mapActionsToProps = ({
    login: authAction.login
})

export default connect(mapStateToProps, mapActionsToProps)(LoginPage);